import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './Thumbnail.module.css';

const thumbnail = (props) => {

   var pathname;
   if (props.proj) {
      pathname = '/projects/' + props.idx + '/overview';
   } else {
      pathname = '/pictures/' + props.idx;
   }

   // activeClassName={props.proj ? classes.projThumbnailImg : classes.picThumbnailImg}

   var displayClass = [];
   if (props.proj && props.active) {
      displayClass.push(classes.projThumbnailImgActive);
   } else if (props.proj && !props.active) {
      displayClass.push(classes.projThumbnailImg);
   } else if (!props.proj && props.active) {
      displayClass.push(classes.picThumbnailImgActive);
   } else if (!props.proj && !props.active) {
      displayClass.push(classes.picThumbnailImg);
   }

   if (props.faint) {
      displayClass.push(classes.Faint);
   }

   return (
      <span className={props.active ? classes.backgroundDiv : null}>
         <NavLink
            to={{
               pathname: pathname,
               state: { from: 'thumbs' }
            }}
         >
            <img
               src={props.img.src}
               className={displayClass.join(' ')}
               alt={props.img.title}
               onClick={() => props.clickProps(props.idx)}
            />
         </NavLink>
      </span>
   );
}

export default thumbnail;
import React, { useState } from 'react';
import Title from '../../UI/Title/Title';
import classes from './ProjViewer.module.css';

const ProjViewer = (props) => {
   // tracks currently viewed Process page
   const [curProcessPg, setCurProcessPg] = useState(0);

   // tracks last viewed project (to know when to "reset" curProcessPg)
   const [lastId, setLastId] = useState(null);

   // Track the last viewed project to 'reset' the curProcessPg
   // Fixes the issue that if the last viewed process page (e.g., Pg6) does not exist 
   // for a subsequently viewed project, the process section will display nothing
   if (props.id !== lastId) {
      setCurProcessPg(0);
      setLastId(props.id);
   }

   const handleClick = (id) => {
      // re-assign content based upon process page clicked (id)
      content = (<div className={classes.processDiv}>{props.project.process[id]}</div>);

      // set new current process page based on click
      setCurProcessPg(id);
   }

   const renderProcessLinks = () => {
      // Create the process page links (eg., Pg1, Pg2 )
      sectionTitle = [];
      sectionTitle.push('Process ');
      if (props.project.process.length > 1) {
         props.project.process.map((el, id) => {
            var processClasses = [classes.inlineLink];
            if (id === curProcessPg) {
               processClasses.push(classes.Active);
            }
            sectionTitle.push(<span className={processClasses.join(' ')} key={id} onClick={() => handleClick(id)}>Pg{id + 1}</span>);
            return id;
         })
      }
   }

   // Set title and content to display based on props.section (associated with ProjSummary link clicked)
   var sectionTitle;
   var content;

   switch (props.section) {
      case 'overview':
         sectionTitle = 'Overview';
         content = props.project.overview;
         break;
      case 'links':
         sectionTitle = 'Links';
         content = props.project.links;
         break;
      case 'challenge':
         sectionTitle = 'Challenge';
         content = props.project.challenge;
         break;
      case 'solution':
         sectionTitle = 'Solution';
         content = props.project.solution;
         break;
      case 'process':
         renderProcessLinks();
         content = (<div className={classes.processDiv}>{props.project.process[curProcessPg]}</div>);
         break;
      default:
         content = 'Click on a section link next to the selected thumbnail to view additional info';
         break;
   }

   return (
      <div>
         <Title>{props.project.title}</Title>
         <div>{props.project.links}</div>
         <h3>{sectionTitle}</h3>
         {content}
      </div>
   );
};

export default ProjViewer;
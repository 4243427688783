import React from 'react';
import classes from './Homepage.module.css';

const getPgLayouts = () => {
   var pgLayouts = [
      {

         head: [
            (<div className={classes.Head}>Let's</div>),
            (<div className={classes.Head}>create</div>),
            (<div className={classes.Head}>something</div>)
         ],
         word: [
            (<div className={classes.Word}>unique</div>)
         ],
         bullet1:
            (
               <div className={classes.Bullet1}>I have</div>
            ),
         bullet: [
            (
               <div className={classes.Bullet}>
                  <div className={classes.Bullet}>business skills</div>
                  <div className={classes.Bullet}>design vision</div>
                  <div className={classes.Bullet}>digital experience</div>
               </div>
            )
         ],
         contact1:
            (
               <div className={classes.Contact1}>Contact</div>
            ),
         contact: [

            (
               <div className={classes.Contact}>jjmchew at gmail.com</div>
            ),
            (
               <div className={classes.Contact}>four 03 975 716 seven</div>
            ),
            (
               <div className={classes.Contact}>linkedin.com/in/jjmchew</div>
            )
         ]

      },
      {

         head: [
            (<div className={classes.Head}>Let's create something</div>)
         ],
         word: [
            (<div className={classes.Word}>unique</div>)
         ],
         bullet1: (
            <div className={classes.Bullet1}>I have</div>
         ),
         bullet: [

            (
               <div className={classes.Bullet}>business skills</div>
            ),
            (
               <div className={classes.Bullet}>design vision</div>
            ),
            (
               <div className={classes.Bullet}>digital experience</div>
            )
         ],
         contact1:
            (
               <div className={classes.Contact1}>Contact</div>
            ),
         contact: [
            (
               <div className={classes.Contact}>jjmchew at gmail.com</div>
            ),
            (
               <div className={classes.Contact} style={{
                  flexDirection: 'column'
               }} >
                  <div className={classes.Contact}>four 03 975 716 seven</div>
                  <div className={classes.Contact}>linkedin.com/in/jjmchew</div>
               </div >
            )
         ]
      }

   ];
   return pgLayouts;
}

export default getPgLayouts;

import React from 'react';
import Container from './Container';

import classes from './draggable.module.css';
import Shake from '../Shake/Shake';

export default class Draggable extends React.Component {
   state = {
      isDragging: false,

      originalX: 0,
      originalY: 0,

      translateX: 0,
      translateY: 0,

      lastTranslateX: 0,
      lastTranslateY: 0
   };

   componentWillUnmount() {
      window.removeEventListener('mousemove', this.handleMouseMove);
      window.removeEventListener('mouseup', this.handleMouseUp);
   }

   handleMouseDown = ({ clientX, clientY }) => {
      window.addEventListener('mousemove', this.handleMouseMove);
      window.addEventListener('mouseup', this.handleMouseUp);

      if (this.props.onDragStart) {
         this.props.onDragStart();
      }

      this.setState({
         originalX: clientX,
         originalY: clientY,
         isDragging: true
      });
   };

   handleMouseMove = ({ clientX, clientY }) => {
      const { isDragging } = this.state;
      const { onDrag } = this.props;

      if (!isDragging) {
         return;
      }

      this.setState(prevState => ({
         translateX: clientX - prevState.originalX + prevState.lastTranslateX,
         translateY: clientY - prevState.originalY + prevState.lastTranslateY
      }), () => {
         if (onDrag) {
            onDrag({
               translateX: this.state.translateX,
               translateY: this.state.translateY
            });
         }
      });
   };

   handleMouseUp = () => {
      window.removeEventListener('mousemove', this.handleMouseMove);
      window.removeEventListener('mouseup', this.handleMouseUp);

      this.setState(
         {
            originalX: 0,
            originalY: 0,
            lastTranslateX: this.state.translateX,
            lastTranslateY: this.state.translateY,

            isDragging: false
         },
         () => {
            if (this.props.onDragEnd) {
               this.props.onDragEnd();
            }
         }
      );
   };


   render() {
      const { children } = this.props;
      const { translateX, translateY, isDragging } = this.state;

      var divStyle;
      var contStyle;
      if (this.props.size === 'sm') {
         divStyle = { height: '23px' }
         contStyle = { position: 'relative', top: '-20px' }
      } else {
         divStyle = { height: '13vh' }
         contStyle = { position: 'relative', top: '-13vh' }
      }

      var shakeMode;
      var rand = Math.floor(Math.random() * 3);
      switch (rand) {
         case 0: shakeMode = 'TL'; break;
         case 1: shakeMode = 'TC'; break;
         case 2: shakeMode = 'TR'; break;
         default: break;
      }

      console.log('draggable refreshed');

      return (
         <div style={divStyle}>
            <button className={classes.gotMeButton} onClick={this.props.clickProps}>Got Me!</button>
            <Shake mode={shakeMode}>
               <Container
                  style={contStyle}
                  onMouseDown={this.handleMouseDown}
                  x={translateX}
                  y={translateY}
                  isDragging={isDragging}
               >
                  {children}
               </Container>
            </Shake>
         </div>
      );
   }
}



const getPicturesData = () => {
   var pictures = [
      {
         title: 'tusk mountain',
         desc: 'As friends, we organized a self-guided trip into the Clemenceau Icefields (heli-in/out!). This shot was taken on our first day of climbing with moody, unsettled weather.',
         src: require('../../assets/pic1.jpg')
      },
      {
         title: 'eindhoven',
         desc: "While on business, free-time coincided with the 2013 Dutch Design week in Eindhoven. It was totally worth the 2 hr drive to be immersed in (free) design culture for the day. Some of the best things (like this street art) weren't even part of the exhibitions - they were just part of the culture.",
         src: require('../../assets/pic2.jpg')
      },
      {
         title: 'birds of a feather',
         desc: "From a boat tour off the coast of Newfoundland.  It's one of the largest mating areas for puffins (and gulls) in the world. I love the texture and colour.",
         src: require('../../assets/pic3.jpg')
      },
      {
         title: 'ride the wave',
         desc: 'We chanced upon a community fundraiser at a local school in Kauai. It was a totally random morning of breakfast, meeting residents, live ukelele, and this piece of art - co-created by the students.',
         src: require('../../assets/pic4.jpg')
      },
      {
         title: 'persistence',
         desc: "When our son was born, we didn't want to stop doing road trips. Our solution was to start late and drive unhindered through the night while he slept. It sort of worked. We were often tired.",
         src: require('../../assets/pic5.jpg')
      },
      {
         title: 'ingenuity',
         desc: 'The space centre in Houston had actual rockets displayed in a giant hangar. The scale and detail of the engineering and design was awe-inspiring.',
         src: require('../../assets/pic6.jpg')
      },
      {
         title: 'uncover',
         desc: 'Corporate re-structuring resulted in a chance visit to a friend on Vancouver Island. This was one of the many things I discovered.',
         src: require('../../assets/pic7.jpg')
      },
      {
         title: '14,000 ft camp',
         desc: "Systems and outcomes are interesting to me. Denali sees so much traffic that camp at 14,000 ft seemed to have it's own distinct culture and feel - like a tiny, organic city.",
         src: require('../../assets/pic8.jpg')
      },
      {
         title: 'memory',
         desc: "While living in Japan, I bought a miniDisc player. The discs were rewriteable and I didn't want to put what could become obsolete text labels on the discs. Instead, I drew symbols and pictures to represent the music on the discs.",
         src: require('../../assets/pic9.jpg')
      },
      {
         title: 'an ode to alpine',
         desc: 'I first started climbing real mountains in Alberta in 2001. I was constantly surprised at the effort and energy expended for an activity that arguably had little purpose. W.B.Yeats provided the words to describe this feeling that I could not (although he was not talking about climbing). I tried to communicate how I had co-opted his words through typography.',
         src: require('../../assets/pic10.jpg')
      },
      {
         title: 'sketch',
         desc: 'I have always enjoyed sketching and drawing.',
         src: require('../../assets/pic11.jpg')
      }
   ];

   return pictures;
};

export default getPicturesData;
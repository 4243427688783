import React, { useState } from 'react';

import getPicturesData from './getPicturesData';
import Thumbnail from '../Navigation/Thumbnail/Thumbnail';
import Picture from '../Pictures/Picture/Picture';
import SubNav from '../Navigation/SubNav/SubNav';
// import Spinner from '../UI/Spinner/Spinner';
import classes from './Pictures.module.css';

const Pictures = (props) => {
   // track whether or not a specific image has been clicked
   const { id } = props.match.params;
   var numId = parseInt(id, 10);

   // track currently active thumbnail (if any)
   const [activeId, setActiveId] = useState(id);

   const handleClick = (idx) => {
      // track thumbnail clicks to concurrently to track 'active' 
      // thumbnail to apply additional css classes
      setActiveId(idx - 1);
   }

   // initialize data object for pictures 
   const pictures = getPicturesData();

   // used to store appropriate CSS classes based on various potential thumbnail views
   var thumbsClass;
   var thumbnails;

   // if id is provided in router URL, then create thumbnails, assign appropriate 
   // display class, and set-up display of specific image
   if (typeof id === "undefined") {
      // assign CSS class
      thumbsClass = classes.ThumbsOnly;

      // create display object of thumbnails (no faint class applied)
      thumbnails = pictures.map((pic, idx) => {
         return (<Thumbnail key={pic.title} proj={false} idx={idx + 1} img={pictures[idx]} clickProps={handleClick} />)
      })
   } else {
      // assign CSS class
      thumbsClass = classes.ThumbsWImg;

      // create display object of thumbnails (consider active/faint)
      thumbnails = pictures.map((pic, idx) => {
         var active;
         if (idx === activeId) { console.log('active'); active = true; } else { active = false; }
         return (<Thumbnail key={pic.title} proj={false} idx={idx + 1} img={pictures[idx]} active={active} faint={!active} clickProps={handleClick} />)
      })


      // Determine links to pass to SubNav
      var prev;
      var next;
      console.log('numID', numId);
      if (numId === 1) {
         prev = null;
         next = '/pictures/2';
      } else if (numId >= 2 && numId < pictures.length) {
         prev = '/pictures/' + (numId - 1);
         next = '/pictures/' + (numId + 1);
      } else if (numId === pictures.length) {
         prev = '/pictures/' + (numId - 1);
         next = null;
      }

      // Display specific Picture
      // var numId = parseInt(id, 10);
      var displayPicture = (
         <React.Fragment>
            <SubNav
               prev={prev}
               next={next}
               title={pictures[numId - 1].title}
               proj={false} />

            <div className={classes.ImgDisplay}>
               <Picture img={pictures[numId - 1]} />
            </div>
         </React.Fragment>
      );

   }

   return (
      <div className={classes.PicturesPage}>
         <div className={thumbsClass}>
            {thumbnails}
         </div>
         <div>
            {displayPicture}
         </div>
      </div>
   )
};

export default Pictures;
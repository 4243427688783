import React from 'react';
import classes from './Container.module.css';

const Container = (props) => {
   var dragStyle = props.isDragging ? classes.Dragging : classes.notDragging;
   // console.log(dragStyle);
   let args = 'translate(' + props.x + 'px,' + props.y + 'px)';
   return (
      <div
         className={dragStyle}
         style={{ transform: args, cursor: 'pointer', ...props.style }}
         onMouseDown={props.onMouseDown}
      >
         {props.children}
      </div>
   );
}
export default Container;

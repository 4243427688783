import React, { useState } from 'react';

import getAboutData from './getAboutData';
import Skill from '../About/Skill/Skill';

import classes from './About.module.css';

const About = () => {
   var aboutData = getAboutData();
   // const [displayExamples, setDisplayExamples] = useState(null);

   // initialize showExamples
   var initialState = {
      coding: [],
      design: [],
      business: []
   };
   for (var i = 0; i < aboutData.skills.coding.length; i++) {
      initialState.coding[i] = false;
   }
   for (i = 0; i < aboutData.skills.design.length; i++) {
      initialState.design[i] = false;
   }
   for (i = 0; i < aboutData.skills.business.length; i++) {
      initialState.business[i] = false;
   }
   // initialize state (to track which skills have their examples showing)
   const [showExamples, setShowExamples] = useState(initialState);

   const handleSkillsClick = (ev, examples, idx, type) => {
      // console.log('clickprops: ', type);
      var updatedShowExamples = { ...showExamples };
      switch (type) {
         case 'business':
            updatedShowExamples.business[idx] = !showExamples.business[idx];
            break;
         case 'coding':
            updatedShowExamples.coding[idx] = !showExamples.coding[idx];
            break;
         case 'design':
            updatedShowExamples.design[idx] = !showExamples.design[idx];
            break;
         default:
            break;
      }
      setShowExamples(updatedShowExamples);
   }

   var businessSkills = aboutData.skills.business.map((el, idx) => {
      return (
         <Skill
            key={idx}
            id={idx}
            title={el.title}
            other={el.other}
            examples={el.examples}
            showExamples={showExamples.business[idx]}
            clickProps={(ev, examples) => handleSkillsClick(ev, examples, idx, 'business')}
         />)
   })

   var codingSkills = aboutData.skills.coding.map((el, idx) => {
      return (
         <Skill
            key={idx}
            id={idx}
            title={el.title}
            other={el.other}
            examples={el.examples}
            showExamples={showExamples.coding[idx]}
            clickProps={(ev, examples) => handleSkillsClick(ev, examples, idx, 'coding')}
         />)
   })

   var designSkills = aboutData.skills.design.map((el, idx) => {
      return (
         <Skill
            key={idx}
            id={idx}
            title={el.title}
            other={el.other}
            examples={el.examples}
            showExamples={showExamples.design[idx]}
            clickProps={(ev, examples) => handleSkillsClick(ev, examples, idx, 'design')}
         />)
   })

   return (
      <div className={classes.aboutPage}>
         <h3 className={classes.title}>I am a Digital Products Designer / Developer</h3>
         <div>{aboutData.statement}</div>

         <div className={classes.flexContainer} >

            <div className={classes.skillsFlexContainer}>
               <h3 className={classes.title}>Design Skills</h3>
               {designSkills}
            </div>
            <div className={classes.skillsFlexContainer}>
               <h3 className={classes.title}>Coding Skills</h3>
               {codingSkills}
            </div>
            <div className={classes.skillsFlexContainer}>
               <h3 className={classes.title}>Business Skills</h3>
               {businessSkills}
            </div>
         </div>
      </div>
   )
};

export default About;
import React from 'react';
import classes from './RotateWord.module.css';

const RotateWord = (props) => {
   var cssClasses = [classes.RotateWord];
   cssClasses.push(props.className);

   if (props.minor) {
      cssClasses.push(classes.Minor);
   }

   return (
      <span className={cssClasses.join(' ')}>{props.word}</span>
   )
};

export default RotateWord;
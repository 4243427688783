import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import './App.css';

import Pictures from './components/Pictures/Pictures';
import Projects from './components/Projects/Projects';
import About from './components/About/About';
import PrepHomePage from './components/HomePage/PrepHomePage';

import Layout from './components/Layout/Layout';

const App = (props) => {

  // define all website routes
  let routes = (
    <Switch>
      <Route exact path='/about' component={About} />
      <Route exact path='/projects' component={Projects} />
      <Route exact path='/projects/:id' component={Projects} />
      <Route exact path='/projects/:id/:section' component={Projects} />
      <Route exact path='/pictures' component={Pictures} />
      <Route exact path='/pictures/:id' component={Pictures} />
      <Route exact path='/' component={PrepHomePage} />
      <Redirect to="/" />
    </Switch>
  );


  // overall layout of website is defined here with routes
  return (
    <React.Fragment>
      <Layout>
        {routes}
      </Layout>
    </React.Fragment>
  );
}

export default App;

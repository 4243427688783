import React from 'react';
import classes from './Skill.module.css';

const Skill = (props) => {
   var examples = props.examples.map((el, id) => {
      return (
         <li className={classes.Examples} key={id}>{el.link}</li>
      );
   })

   var displayExamples = (
      <div className={classes.exampleContainer}>
         <div style={{ textAlign: 'center', marginBottom: '10px' }}>{props.other}</div>

         <div className={classes.exampleTitle}>See:</div>
         <ul style={{ margin: '2px 0 0 0' }}>
            {examples}
         </ul>
      </div>
   );

   return (
      <React.Fragment>
         <div className={classes.skillContainer}>
            <div className={props.showExamples ? classes.SkillOpen : classes.SkillClosed} onClick={(ev) => props.clickProps(ev, examples)}>
               {props.title}
               {props.showExamples ? displayExamples : null}
            </div>
         </div>
      </React.Fragment>
   );
};

export default Skill;
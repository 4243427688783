import React, { useState } from 'react';

import Logo from '../Navigation/Logo/Logo';
import NavItems from '../Navigation/NavItems/NavItems';

import classes from './Layout.module.css';

const Layout = (props) => {

   // define items within the main navigation
   let items = [
      { text: 'About', to: '/about' },
      { text: 'Projects', to: '/projects' },
      { text: 'Pictures', to: '/pictures' }
   ];

   // order of main navigation determined by menu state
   const menu = useState([...items])[0];

   const changeMenuOrder = (event) => {
      event.persist();
      // console.log('clicked');
      console.log(event.target.href);

      // var newMenu = [];
      // let index;
      // for (var i = 0; i < items.length; i++) {
      //   if (items[i].to === props.location.pathname) {
      //     index = i;
      //   }
      // }
      // // let index = items.indexOf(Math.floor(Math.random() * 3);

      // newMenu[0] = items[index];
      // let j = 1;
      // for (i = 0; i < 3; i++) {
      //   if (i !== index) {
      //     newMenu[j] = items[i];
      //     j++;
      //   }
      // }
      // setMenu(newMenu);
   }

   // console.log(props);


   return (
      <React.Fragment>
         <div className={classes.titleBar}>
            <Logo />
            <NavItems items={menu} clickProps={changeMenuOrder} />
         </div>
         <main className={classes.Content}>{props.children}</main>
      </React.Fragment>
   );
};

export default Layout;
import React from 'react';
import classes from './Shake.module.css';


const Shake = (props) => {
   var cssClasses;
   switch (props.mode) {
      case 'TL':
         cssClasses = classes.swingTL;
         break;
      case 'TR':
         cssClasses = classes.swingTR;
         break;
      case 'TC':
         cssClasses = classes.swingTC;
         break;
      default:
         break;
   }
   console.log(props.mode);
   return (
      <div className={cssClasses}>{props.children}</div>
   )
};

export default Shake;

import React, { useState } from 'react';

import getProjectsData from './getProjectsData';

import Thumbnail from '../Navigation/Thumbnail/Thumbnail';
import classes from './Projects.module.css';
import ProjSummary from './ProjSummary/ProjSummary';
import Project from './Project/Project';
import ProjViewer from './ProjViewer/ProjViewer';
import SubNav from '../Navigation/SubNav/SubNav';

const Projects = (props) => {

   // track whether or not a specific project / section has been clicked
   const { id, section } = props.match.params;

   // track currently active thumbnail (if any)
   const [activeId, setActiveId] = useState(parseInt(id - 1, 10));

   const projects = getProjectsData();

   const handleClick = (id) => {
      setActiveId(id - 1);
   }

   // Determine size of existing browser window
   // (to determine which project view to load)
   var screenW = window.innerWidth;
   var screenH = window.innerHeight;
   // console.log("window: ", screenW, screenH);

   // create display object of thumbnails
   var thumbnails;

   // store appropriate CSS classes
   var thumbsClass;

   // -- Set-up display for generic projects (thumbnails) page (i.e, no id selected) ----------------
   if (typeof id === "undefined") {
      thumbsClass = classes.ThumbsOnly;
      thumbnails = projects.map((proj, idx) => {
         return (<Thumbnail key={proj.title} proj={true} idx={idx + 1} img={projects[idx]} clickProps={handleClick} />)
      })

   } else {
      // -- Set-up display for of thumbnails w/ summary links - note:  active thumbnail goes first for projects
      thumbnails = [];
      thumbnails.push((<ProjSummary key={projects[activeId].title} proj={true} idx={activeId + 1} project={projects[activeId]} active={true} faint={false} clickProps={handleClick} />))
      var temp = projects.map((proj, idx) => {
         var active;
         var returnLine = null;
         if (idx === activeId) {
            active = true;
         } else {
            active = false;
            returnLine = (<ProjSummary key={proj.title} proj={true} idx={idx + 1} project={projects[idx]} active={active} faint={!active} clickProps={handleClick} />)
         }
         return returnLine;
      });
      thumbnails.push(temp);

      // ------------- old working way  -----------------
      // thumbnails = projects.map((proj, idx) => {
      //    // console.log('if idx:', idx);
      //    var active;
      //    if (idx === activeId) { console.log('active'); active = true; } else { active = false; }
      //    return (<ProjSummary key={proj.title} proj={true} idx={idx + 1} project={projects[idx]} active={active} faint={!active} clickProps={handleClick} />)
      // })
      // ------------- old working way  -----------------

      // Display specific Project
      var numId = parseInt(id, 10);
      var displaySection = (
         <div className={classes.displaySection}>
            <ProjViewer project={projects[numId - 1]} section={section} id={numId} />
         </div>
      );

      // Determine links to pass to SubNav
      var prev;
      var next;

      if (numId === 1) {
         prev = null;
         next = '/projects/2';
      } else if (numId >= 2 && numId < projects.length) {
         prev = '/projects/' + (numId - 1);
         next = '/projects/' + (numId + 1);
      } else if (numId === projects.length) {
         prev = '/projects/' + (numId - 1);
         next = null;
      }

      thumbsClass = classes.ThumbsWDetail;
      var displayProject = (
         <div className={classes.displayProject}>
            <SubNav
               prev={prev}
               next={next}
               title={projects[numId - 1].title}
               proj={true} />

            <Project project={projects[numId - 1]} />
         </div>
      );

   }


   return (
      <div className={classes.ProjectsPage}>
         <div className={thumbsClass}>
            {thumbnails}
         </div>
         {displayProject}
         {displaySection}
      </div>
   )
};

export default Projects;
import React from 'react';
import { NavLink } from 'react-router-dom';

import JClogo from '../../../assets/JClogo.png';
import classes from './Logo.module.css';

const logo = props => (
   // display JClogo and associated name text
   <React.Fragment>
      {/* <div className="Triangle" /> */}
      <div className={classes.titleBar}>
         <img className={classes.JClogo} src={JClogo} alt="JC" />
         <NavLink className={classes.logoText} to={'/'}>
            James Chew
      </NavLink>
      </div>
   </React.Fragment>
);

export default logo;

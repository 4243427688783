import React from 'react';
import classes from './Modal.module.css';

const modal = React.memo((props) => {
   var cssClasses = [classes.modal];
   if (props.show) { cssClasses.push(classes.open) }
   else { cssClasses.push(classes.closed) }

   var modalClosed;

   var modalOpen;
   if (props.skeletonDead) {
      modalOpen = (
         <React.Fragment>
            <div className={classes.content} style={{ fontSize: '28px' }}>Ack - hear me from the grave!</div>
            <div className={[classes.cross, classes.crossPosition].join(' ')} />
            <br />
            {!props.clicks ? <div className={classes.content}>Why dontcha play?</div> : null}
            {props.clicks ? <div className={classes.content}>Go champ: You've found it {props.clicks} times!</div> : null}
         </React.Fragment>
      );
      modalClosed = (
         <div className={classes.closed} onClick={props.clickProps}> your score </div>
      );
   } else {
      modalOpen = (
         <React.Fragment>
            <div className={classes.content} style={{ fontSize: '28px' }}>So you like games, eh?</div>
            <div className={[classes.cross, classes.crossPosition].join(' ')} />
            <div className={classes.content}>  Push the "Let's Play!" button to start,</div>
            <div className={classes.content}>  then look for the "Got me!" button... </div>
            <div className={classes.content}>  it's hiding somewhere behind stuff.</div>
            <div className={classes.content}>  Hint - try dragging stuff out of the way.</div>
            <div className={classes.content}>  Click "Got me!" for a small surprise.</div>
            <br />
            <div className={classes.content}>  Whatever you do, don't click me!</div>
         </React.Fragment>
      );
      modalClosed = (
         <div className={classes.closed} onClick={props.clickProps}>Listen!</div>
      );
   }

   return (
      <div className={props.show ? classes.open : classes.closed} onClick={props.clickProps}>
         {props.show ? modalOpen : modalClosed}
      </div>
   )
});

export default modal;
import React from 'react';
import classes from './Project.module.css';
import Title from '../../UI/Title/Title';

const Project = (props) => {

   return (
      <div id="top" className={classes.Project}>
         <div className={classes.projTitle}>
            <Title>{props.project.title}</Title>
         </div>
         {props.project.links}
         <h3>Overview</h3>
         {props.project.overview}
         <h3>Challenge</h3>
         {props.project.challenge}
         <h3>Process</h3>
         {props.project.process}
         <h3>Solution</h3>
         {props.project.solution}
         <br />
         <a href="#top" className={[classes.inlineLink, classes.pageBottom].join(' ')}>
            Back to Top </a>

      </div >

   );
};

export default Project;

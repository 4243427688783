import React from 'react';

import classes from './Picture.module.css';

const picture = (props) => {
   return (
      <div className={classes.imgDiv}>
         <div className={classes.picTitle}>{props.img.title}</div>
         <img
            src={props.img.src}
            className={classes.picDisplay}
            alt={props.img.title}
         />
         <div className={classes.picDesc}>{props.img.desc}</div>
      </div>

   );
};

export default picture;
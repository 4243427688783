

import React, { useState } from 'react';

// skeleton.css contains css specific to the skeleton
import './Skeleton.css';

const Skeleton = (props) => {

   const [button, setButton] = useState(true);

   const handleClick = () => {
      setButton(false);
      props.clickProps();
   }

   var pageContent = [];

   if (props.show === 'base') {
      if (Math.floor(Math.random() * 100 + 1) % 3 === 0) {
         pageContent.push(<div id="base">{'  '}</div>);
      }
   } else if (props.show === 'click') {
      pageContent.push(
         <div id={button ? 'walk' : 'die'} onClick={handleClick}>
            {'  '}
         </div>
      );
   } else if (Math.floor(Math.random() * 100 + 1) % 7 === 0) {
      pageContent.push(<div id="walkOff">{'  '}</div>);
   }
   return <div>{pageContent}</div>;

}

export default Skeleton;

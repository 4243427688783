import React from 'react';
import classes from './Project/Project.module.css';

const getProjectsData = () => {
   var projects = [
      {
         title: 'Book Exchanger',
         src: require('../../assets/proj1.png'),
         overview: (
            <ul key='1'>
               <li key='2'>Final project for coding bootcamp: designed, developed, and deployed a responsive, mobile-friendly front-end interface using <strong>Angular, Bootstrap, Javascript, HTML, CSS</strong> </li>
               <li key='3'>Adapted and deployed a back-end MySQL database and corresponding API to a hosted web server using <strong>node.js, Express (database API), MySQL</strong></li>
               <li key='4'>Additionally, designed logo, web identity, and homepage content to improve visual appeal over original mock-ups</li>
            </ul>),
         links: (
            <React.Fragment>
               <div key='5'>
                  Live demo site:{' '}
                  <a
                     target="_blank"
                     rel="noopener noreferrer"
                     className={classes.inlineLink}
                     href="http://www.jjmchew.a2hosted.com/bookexchange2/"
                  >
                     Book Exchanger
                  </a>
               </div>
               <div key='6'>
                  Code:{' '}
                  <a
                     target="_blank"
                     rel="noopener noreferrer"
                     className={classes.inlineLink}
                     href="https://github.com/jjmchew/bookexchange"
                  >
                     Github Repository
                  </a>
               </div>
            </React.Fragment>),
         challenge: (
            <React.Fragment>
               <ul key='7'>
                  <li key='8'>On limited time and expertise, create an online book exchange portal with a partner</li>
                  <li key='9'>Our goals:</li>
                  <ul>
                     <li key='10'>mobile-friendly and responsive,</li>
                     <li key='11'>a simple, intuititive, robust front-end, and </li>
                     <li key='12'>minimum possible number of different screens to be created for minimum viable functionality (posting, browsing books, viewing posting details)</li>
                  </ul>
               </ul>
            </React.Fragment>),
         process: [(
            <React.Fragment>
               <ul key='13'><li key='14'>Key use cases were discussed and documented</li> </ul>
               <img
                  key='15'
                  src={require('../../assets/proj01-01.PNG')}
                  className={classes.projectImg}
                  alt="Use cases"
               />
            </React.Fragment>),
         (
            <ul key='16'>
               <li key='17'>Rough paper sketches were used to develop a simple sitemap and establish required database fields and overall coding structure (e.g., main components, and API)</li>
               <li key='18'>(sorry, no images available)</li>
            </ul>),
         (
            <React.Fragment>
               <ul key='19'>
                  <li key='20'>Interface mock-ups were developed to provide initial guidance prior to coding (example of home page below)</li>
               </ul>
               <img
                  key='21'
                  src={require('../../assets/proj01-02.jpg')}
                  className={classes.projectImg}
                  alt="Mock-up 1"
               />
            </React.Fragment>),
         (
            <React.Fragment>
               <ul key='22'>
                  <li key='23'>Interface mock-up of browse books page below</li>
               </ul>
               <img
                  key='24'
                  src={require('../../assets/proj01-03.jpg')}
                  className={classes.projectImg}
                  alt="Mock-up 2"
               />
            </React.Fragment>),
         (
            <React.Fragment>
               <ul key='25'>
                  <li key='26'>Interface mock-up of book posting detail below</li>
               </ul>
               <img
                  key='27'
                  src={require('../../assets/proj01-04.jpg')}
                  className={classes.projectImg}
                  alt="Mock-up 3"
               />
            </React.Fragment>),
         (
            <ul key='28'>
               <li key='29'>An initial prototype of the site was deployed and made available for test users</li>
               <li key='30'>This is when the missed use case on removal of postings was identified and fixed (see discussion on 'posting password' in Solution section)</li>
            </ul>
         ),
         (<React.Fragment>
            <ul key='31'>
               <li key='32'>Finally, the updated visual identity was developed and implemented, as below</li>
            </ul>
            <img
               key='33'
               src={require('../../assets/proj01-05.jpg')}
               className={classes.projectImg}
               alt="Updated look and feel"
            />
         </React.Fragment>
         )],
         solution: (
            <ul key='34'>
               <li key='35'>The final solution required only 3 distinct screens (1 implemented as a modal pop-up) to accomplish all required use cases</li>
               <ul key='36'>
                  <li key='37'>During initial testing, we identified a missed use case - allowing users to remove old postings</li>
                  <li key='38'>A "posting password" solution was developed to maintain the streamlined
                     nature of the interface and minimize front and back-end complexity</li>
                  <li key='39'>The creation of full user accounts and login would have created unnecessary interface complexity without justifable user benefit solely to implement removal of postings</li>
               </ul>
               <li key='40'>On the homepage, a CSS grid (rather than a more complex Javascript solution) was used to force alignment of text elements between the "Find a new book" and "Offer an old book" cards while re-sizing the browser</li>
            </ul>
         )
      },

      {
         title: 'Online Portfolio',
         src: require('../../assets/JClogo.png'),
         overview: (
            <ul key='41'>
               <li key='42'>Developed and deployed all code for responsive and adaptive, mobile-friendly, online portfolio using <strong>React, react-router, Javascript, HTML, CSS</strong> </li>
               <li key='43'>No back-end database, but data is structured to allow future use of database, if desired</li>
               <li key='44'>All concept, design, photography, images and logo within the site are my own creation, but the skeleton sprites I used are courtesy of{' '}
                  <a
                     target="_blank"
                     rel="noopener noreferrer"
                     className={classes.inlineLink}
                     href="https://jesse-m.itch.io/skeleton-pack"
                  >
                     this site
               </a></li>
            </ul>),
         links: (
            <React.Fragment>
               <div key='45'>
                  This site:{' '}
                  <a
                     target="_blank"
                     rel="noopener noreferrer"
                     className={classes.inlineLink}
                     href="http://www.jjmchew.a2hosted.com/portfolio/"
                  >
                     http://www.jjmchew.a2hosted.com/portfolio/
                  </a>
               </div>
               <div key='46'>
                  Code:{' '}
                  <a
                     target="_blank"
                     rel="noopener noreferrer"
                     className={classes.inlineLink}
                     href="https://github.com/jjmchew/portfolio"
                  >
                     Github Repository
                  </a>
               </div>
            </React.Fragment>),
         challenge: (
            <ul key='47'>
               <li key='48'>Design and develop a unique custom layout and interface to communicate coding and professional skills</li>
               <li key='49'>Create a simple framework and code structure to accommodate (future) content flexibly</li>
            </ul>),
         process: [(
            <React.Fragment>
               <ul key='50'>
                  <li key='51'>Defined rough 'creative brief' based upon objectives defined within challenge section</li>
                  <li key='52'>Concurrently developed simple, sketched wireframes and sitemap</li>
                  <li key='53'>Wireframe sketches are displayed below</li>
               </ul>
               <img
                  key='54'
                  src={require('../../assets/proj02-03.jpg')}
                  className={classes.projectImg}
                  alt="Wireframes"
               />
            </React.Fragment>
         ),
         (
            <React.Fragment>
               <ul key='55'>
                  <li key='56'>Image of final sitemap is displayed below</li>
               </ul>
               <img
                  key='57'
                  src={require('../../assets/proj02-02.jpg')}
                  className={classes.projectImg}
                  alt="Sitemap"
               />
            </React.Fragment>
         ),
         (
            <React.Fragment>
               <ul key='58'>
                  <li key='59'>Some style tiles were developed to explore variations in visual design prior to coding (example below)</li>
               </ul>
               <img
                  key='60'
                  src={require('../../assets/proj02-04.jpg')}
                  className={classes.projectImg}
                  alt="Style Tile 1"
               />
            </React.Fragment>
         ),
         (
            <React.Fragment>
               <ul key='61'>
                  <li key='62'>Another style tile example shown below</li>
               </ul>
               <img
                  key='63'
                  src={require('../../assets/proj02-05.jpg')}
                  className={classes.projectImg}
                  alt="Style Tile 2"
               />
            </React.Fragment>
         ),
         (
            <React.Fragment>
               <ul key='64'>
                  <li key='65'>Another style tile example shown below</li>
               </ul>
               <img
                  key='66'
                  src={require('../../assets/proj02-06.jpg')}
                  className={classes.projectImg}
                  alt="Style Tile 3"
               />
            </React.Fragment>
         ),
         (
            <React.Fragment>
               <ul key='67'>
                  <li key='68'>Updates to the website continue to be made on an ongoing basis</li>
               </ul>
            </React.Fragment>
         )
         ],
         solution: (
            <React.Fragment>
               <ul key='69'>
                  <li key='70'>The most recent version of the site includes extensive user interface (UI) updates to accommodate mobile devices, tablets and desktops</li>
                  <li key='71'>On a desktop, try re-sizing your browser window to see how the interface responds</li>
               </ul>
            </React.Fragment>
         )
      },
      {
         title: 'InclineDesign Sit-Ski',
         src: require('../../assets/proj3-01.jpg'),
         overview: (
            <ul key='72'>
               <li key='73'>Researched, manufactured, tested, and sold a novel sit-ski prototype designed to support sit-ski teaching programs</li>
               <li key='74'>Created an incorporated entity to leverage NSERC IRAP funding for prototype development and to hire a full-time engineering employee</li>
               <br />
               <li key='75' className={classes.sidenote}>A sit-ski is a ski device used by paraplegics to downhill ski (like in the thumbnail pic)</li>
            </ul>),
         links: (
            <React.Fragment>
               <div key='76'>
                  Video discussing an early prototype: {' '}
                  <a
                     target="_blank"
                     rel="noopener noreferrer"
                     className={classes.inlineLink}
                     href="https://youtu.be/bcyrHe-pCws"
                  >
                     https://youtu.be/bcyrHe-pCws
               </a>
               </div>
               <div key='77'>
                  Original corporate website (outdated):{' '}
                  <a
                     target="_blank"
                     rel="noopener noreferrer"
                     className={classes.inlineLink}
                     href="http://www.inclinedesign.ca"
                  >
                     www.inclinedesign.ca
               </a>
               </div>
            </React.Fragment>),
         challenge: (
            <ul key='78'>
               <li key='79'>Develop a sit-ski frame to support not-for-profit teaching programs such as Calgary Association for Disabled Skiing (CADS) in Calgary</li>
               <li key='80'>Equipment must be:</li>
               <ul>
                  <li key='81'>easy to manufacture (and thus customize) in small batches and on-demand (eg, limited tooling and specialized labour like welding)</li>
                  <li key='82'>assembled by volunteers and thus easily maintained and repaired</li>
               </ul>
               <br />
               <li key='83' className={classes.sidenote}>Introduction and participation in sit-skiing relies primarily on not-for-profit, volunteer-supported sit-ski teaching programs given the cost and complexity of the equipment</li>
               <li key='84' className={classes.sidenote}>Existing equipment is very expensive to buy and maintain, and in many cases unsuitable (eg size, weight, geometry) for new participants and especially programs</li>
            </ul>
         ),
         process: [(
            <React.Fragment>
               <ul key='85'>
                  <li key='86'>Initially, as part of a group graduate school project, we conducted design research, including competitor and landscape analysis, user interviews with sit-skiiers and instructors, observation of sit-ski teaching and personal participation as new sit-skiiers</li>
                  <li key='87'>The initial design developed was primarily a visual design with limited consideration for manufacturability and function (see below)</li>
               </ul>
               <img
                  key='88'
                  src={require('../../assets/proj3-02.jpg')}
                  className={classes.projectImg}
                  alt="Initial sit ski industrial design"
               />
            </React.Fragment>
         ),
         (<React.Fragment>
            <ul key='89'>
               <li key='90'>I later explored manufacturing options in a variety of materials and configurations with local manufacturers (example below)</li>
               <li key='91'>The resulting prototype was based upon our initial visual design, but truly manufacturable</li>
            </ul>
            <img
               key='92'
               src={require('../../assets/proj3-03.jpg')}
               className={classes.projectImg}
               alt="manufacturing options"
            />
         </React.Fragment>
         )],
         solution: (
            <React.Fragment>
               <ul key='93'>
                  <li key='94'>The current sit-ski design below meets all initial criteria (easy to manufacture / customize / assemble)</li>
                  <li key='95'>An additional benefit was that various parts could be produced in a variety of materials or similar manufacturing processes and thus effectively leverage donated time or money – an important factor for a not-for-profit enterprise</li>
               </ul>
               <img
                  key='96'
                  src={require('../../assets/proj3-04.jpg')}
                  className={classes.projectImg}
                  alt="Final prototype"
               />
            </React.Fragment>
         )
      },

      {
         title: 'Legal Registry',
         src: require('../../assets/proj4.png'),
         overview: (
            <ul key='97'>
               <li key='98'>Management consulting project for large energy corporation involving design and implementation of entirely new cross-functional processes and associated enterprise system database / user interface (SAP)</li>
               <li key='99'>Designed and implemented a regulatory compliance and change management program which impacted 11+ corporate departments across 2 business units</li>
               <li key='100'>Involved in all assessment and proposal of initial requirements for new processes, development, training, reporting and project management</li>
               <li key='101'>Led the brainstorming and workshops associated with developing detailed process steps and enterprise system database / interface, and the subsequent training and user workshops to roll-out critical processes to implement the program</li>
            </ul>),
         links: (<div key='102'>Design and implementation of corporate regulatory management program involving complex <strong>stakeholder engagement and project management</strong></div>),
         challenge: (
            <ul key='103'>
               <li key='104'>In addition to meeting specific regulatory requirements, the final design also needed to integrate with a number of the client's existing management systems, impacted processes and governance structures</li>
               <li key='105'>A significant amount of time and input was also required from subject matter experts across various departments and business units</li>
               <li key='106'>As a result of regulatory requirements, a relatively short time-frame was available to engage, design and implement the processes to be designed</li>
            </ul>),
         process: [(
            <React.Fragment>
               <ul key='107'>
                  <li key='108'>Initial research included document review and independent meetings with stakeholders to understand their concerns and needs and establish project and design constraints</li>
                  <li key='109'>Develop and review an initial process framework (e.g., SIPOC - suppliers, inputs, processes, outputs, customers, see example below)</li>
               </ul>
               <img
                  key='110'
                  src={require('../../assets/proj4-02a.PNG')}
                  className={classes.projectImg}
                  alt="example SIPOCs"
               />
            </React.Fragment>
         ),
         (
            <React.Fragment>
               <ul key='111'>
                  <li key='112'>A set of SIPOCs were developed to help frame and scope required processes within the overall program and their relationships</li>
                  <li key='113'>An overall, initial, unifying process flow was developed and more detailed feedback obtained through a group workshop to ensure dependencies and cross-functional impacts were understood (see example below)</li>
               </ul>
               <img
                  key='114'
                  src={require('../../assets/proj4-03.jpg')}
                  className={classes.projectImg}
                  alt="Process Feedback 1"
               />
            </React.Fragment>
         ),
         (
            <React.Fragment>
               <ul key='115'>
                  <li key='116'>Another example of feedback from the group workshop is below</li>
               </ul>
               <img
                  key='117'
                  src={require('../../assets/proj4-04.jpg')}
                  className={classes.projectImg}
                  alt="Process Feedback 2"
               />
            </React.Fragment>
         ),
         (
            <React.Fragment>
               <ul key='118'>
                  <li key='119'>To ensure key people and services were available to support process implementation, owners were determined and training conducted to highlight critical aspects of the process and the required actions</li>
                  <li key='120'>Tools, such as RACI tables, were developed to consolidate and communicate responsibilities to stakeholders</li>
               </ul>
            </React.Fragment>
         )
         ],
         solution: (
            <React.Fragment>
               <ul key='121'>
                  <li key='122'>By the time final documentation of the process was developed, stakeholders were well aware of their responsibilities and activities</li>
                  <li key='123'>The example process flow below was taken from the final documentation used for formal sign-off and official commencement of the process</li>
               </ul>
               <img
                  key='124'
                  src={require('../../assets/proj4-07.jpg')}
                  className={classes.projectImg}
                  alt="Process RACI"
               />
            </React.Fragment>
         )
      },

      {
         title: 'T-shirt Designs',
         src: require('../../assets/proj5.PNG'),
         overview: (
            <ul key='125'>
               <li key='126'>A series of personal tshirt designs were created from scratch using <strong>Adobe Illustrator and Adobe Photoshop</strong> and digitally printed or silkscreened onto actual tshirts</li>
            </ul>),
         links: (
            <React.Fragment>
               My favourite designs are available on actual tshirts here:{' '}
               <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.inlineLink}
                  href="https://raddot.threadless.com/"
               >
                  https://raddot.threadless.com/
          </a>
            </React.Fragment>),
         challenge: (
            <ul key='127'>
               <li key='128'>I chose to coincidentally fill a void in both my creative-life and wardrobe by designing unique tshirts that I could identify with</li>
               <li key='129'>Climbing and martial arts have both been significant aspects of my former lives</li>
               <li key='130'>Cost-effective production of actual shirts quickly became a key consideration after a few initial (more colorful) designs</li>
            </ul>),
         process: [
            (
               <React.Fragment>
                  <ul key='131'>
                     <li key='132'>Some preliminary sketches were done on paper, but many of the designs were developed iteratively by working directly in Illustrator and using (sometimes Photoshop-processed) digital images as references</li>
                     <li key='133'>I specifically wanted to develop scalable vector art</li>
                     <li key='134'>Initial designs, like the ones below were more colourful and ended-up being significantly more expensive to produce actual shirts in small numbers</li>
                  </ul>
                  <img
                     key='135'
                     src={require('../../assets/proj5-02.PNG')}
                     className={classes.projectImg}
                     alt="game of death"
                  />
                  <img
                     key='136'
                     src={require('../../assets/proj5-03.PNG')}
                     className={classes.projectImg}
                     alt="ishihara"
                  />
               </React.Fragment>
            ),
            (
               <React.Fragment>
                  <ul key='137'>
                     <li key='138'>Ultimately, single colour designs were most cost-effective to produce</li>
                     <li key='139'>The interim design below required 2 colours for shading</li>
                  </ul>
                  <img
                     key='140'
                     src={require('../../assets/proj5-05.PNG')}
                     className={classes.projectImg}
                     alt="robson"
                  />
               </React.Fragment>
            )
         ],
         solution: (
            <React.Fragment>
               <ul key='141'>
                  <li key='142'>Ultimately, I created a monochromatic design of a local Canmore mountain and silk-screened a limited run of 10 shirts that sold through a local Calgary boutique retailer</li>
                  <li key='143'>Of all the designs I ended up turning into actual tshirts, this design was the most cost-effective - I made a small profit from sales after accounting for cost of goods produced and the shirts I kept</li>
               </ul>
               <img
                  key='144'
                  src={require('../../assets/proj5-06.PNG')}
                  className={classes.projectImg}
                  alt="blue ha ling"
               />
            </React.Fragment>
         )
      },

      {
         title: 'myBurger',
         src: require('../../assets/proj06.png'),
         overview: (
            <ul key='145'>
               <li key='146'>Course project for online coding course: developed and deployed a responsive, mobile-friendly front-end interface using <strong>React (including react-router, redux, axios), Javascript, HTML, CSS</strong></li>
               <li key='147'>Implemented backend database and user authentication using <strong>Google Firebase database with REST and AUTH APIs</strong></li>
            </ul>),
         links: (
            <React.Fragment>
               <div key='148'>
                  Live demo site:{' '}
                  <a
                     target="_blank"
                     rel="noopener noreferrer"
                     className={classes.inlineLink}
                     href="http://www.jjmchew.a2hosted.com/myburger/"
                  >
                     myBurger
                  </a>
               </div>
            </React.Fragment>),
         challenge: (
            <ul key='149'>
               <li key='150'>Create a relatively simple application to apply course concepts including:</li>
               <ul key='151'>
                  <li key='152'>application component design</li>
                  <li key='153'>state management using redux</li>
                  <li key='154'>asynchronous http requests</li>
                  <li key='155'>use of React context to manage authentication features</li>
               </ul>
            </ul>),
         process: [(
            <React.Fragment>
               <ul key='156'>
                  <li key='157'>Application functionality was built up gradually through successive iterations</li>
                  <li key='158'>A summary description of each iteration follows: </li>
                  <ol key='159'>
                     <li key='160'>the Burger Builder: adding / removing ingredients to build a burger</li>
                     <li key='161'>adding the main navigation and responsive side drawer for mobile</li>
                     <li key='162'>creating an order summary, contact data form, and input validation</li>
                     <li key='163'>adding Firebase database connections and orders page</li>
                     <li key='164'>adding account authentication and associated menus</li>
                  </ol>
               </ul>
            </React.Fragment>
         )],
         solution: (
            <ul key='165'>
               <li key='166'>Although there's still room for improvement, the current version of myBurger is a great demonstration of a variety of common web application features in React</li>
            </ul>
         )
      },

      {
         title: 'Scheduler',
         src: require('../../assets/proj07-01.PNG'),
         overview: (
            <ul key='167'>
               <li key='168'>For a SME (small, medium enterprise) client: Designed and developed a custom front-end interface using <strong>React, Javascript, HTML, CSS</strong></li>
               <li key='169'>The interface was designed to more clearly present and interact with data stored on an under-utilized central database</li>
               <li key='170'>The interface is currently being tested</li>
               <li key='171'>Someone else was responsible for deploying the interface and connecting to the existing client database</li>
            </ul>),
         links: (
            <React.Fragment>
               <div key='172' style={{ marginLeft: '25px' }}>Sorry - since this work was for a client, you'll have to imagine from the screenshots</div>
               <img
                  key='173'
                  src={require('../../assets/proj07-01.PNG')}
                  className={classes.projectImg}
                  alt="scheduler screenshot"
               />
            </React.Fragment>),
         challenge: (
            <ul key='174'>
               <li key='175'>The client's existing system was significantly under-utilized resulting in missing / incorrect information for project managers and resources</li>
               <li key='176'>A custom interface for the existing system/database was chosen to leverage existing software / database assets by improving the data quality and usability</li>
               <li key='177'>Thus, goals for the interface were:</li>
               <ul key='178'>
                  <li key='179'>Make data understandable at-a-glance: identify resourcing needs/gaps, understand resourcing allocations</li>
                  <li key='180'>Make key data interactions quick and easy: assign / unassign resources to existing job pool</li>
               </ul>
            </ul>),
         process: [(
            <React.Fragment>
               <ul key='181'>
                  <li key='182'>Initial mock-ups were developed to discuss potential data views</li>
               </ul>
               <img
                  key='183'
                  src={require('../../assets/proj07-02.PNG')}
                  className={classes.projectImg}
                  alt="scheduler mock-up1"
               />
            </React.Fragment>
         ),
         (
            <React.Fragment>
               <ul key='184'>
                  <li key='185'>Client feedback incorporated into the next iteration mock-up</li>
               </ul>
               <img
                  key='186'
                  src={require('../../assets/proj07-03.PNG')}
                  className={classes.projectImg}
                  alt="scheduler mock-up2"
               />
            </React.Fragment>
         ),
         (
            <React.Fragment>
               <ul key='187'>
                  <li key='188'>An initial prototype focused on data visualization to understand job requirements</li>
                  <li key='189'>Subsequently, the assign / unassign tools were added to allow the client to pilot the interface and identify further requirements</li>
               </ul>
               <img
                  key='190'
                  src={require('../../assets/proj07-04.PNG')}
                  className={classes.projectImg}
                  alt="scheduler screenshot - tools displayed"
               />
            </React.Fragment>
         )
         ],
         solution: (
            <React.Fragment>
               <ul key='191'>
                  <li key='192'>The current interface leverages only a single screen split into 2 primary areas to reflect both key areas of information focus:  jobs and resource availability</li>
                  <li key='193'>Additional information is provided to the user via the use of 'hover' info boxes</li>
                  <li key='194'>Interface areas (like buttons or table cells) dynamically change appearance to guide users and eliminate interface errors</li>
                  <li key='195'>Tools are set-up to allow flexible individual selections or drag-select of multiple boxes to speed selections</li>
               </ul>
            </React.Fragment>
         )
      }



   ];

   return projects;
};

export default getProjectsData;

// {
//    title: ,
//    src: require('../../assets/proj1.png'),
//    overview: (
//       <ul>

//       </ul>),
//    links: (
//       <React.Fragment>

//       </React.Fragment>),
//    challenge: (
//       <ul>

//       </ul>),
//    process: [(
//       <React.Fragment>
//          <ul>
//          </ul>
//       </React.Fragment>
//    )],
//    solution: (
//       <ul>

//       </ul>
//    )
// }
import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './NavItems.module.css';

const NavItems = (props) => {

   // define navigation items; use array to support re-ordered animations in future
   let menu = [...props.items];


   // create display object for navigation
   let nav = menu.map((el, idx) => (
      <NavLink
         key={idx}
         className={classes.linkItem}
         activeClassName={classes.activeLinkItem}
         to={el.to}
         onClick={(event) => props.clickProps(event)}
      >
         {el.text}
      </NavLink>
   ));

   return (
      <div className={classes.navItems}>
         {nav}
      </div>
   );
};

export default NavItems;

// Check https://github.com/aholachek/react-flip-toolkit#list-transitions
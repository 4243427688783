import React from 'react';
import HomePage from './HomePage';

const PrepHomePage = () => {

   // generate random circles here to prevent unnecessary re-render cycles in HomePage
   const createCircles = () => {
      let tempDisplayObj = [];
      var size = 0;
      var xpos,
         ypos = '';
      var r,
         g,
         b = 0;
      var colour,
         bcolour = '';

      for (var i = 0; i < 200; i++) {

         var animationContent =
            'flicker ' + (Math.random() * 6 + 1) + 's ease alternate infinite';

         size = Math.floor(Math.random() * 100 + 1);
         xpos = Math.floor(Math.random() * 100 + 1) + 'vh';
         ypos = Math.floor(Math.random() * 100 + 1) + 'vw';
         r = Math.floor(Math.random() * 255 + 1);
         g = Math.floor(Math.random() * 255 + 1);
         b = Math.floor(Math.random() * 255 + 1);
         colour = 'rgb(' + r + ', ' + g + ', ' + b + ')';
         bcolour =
            'rgb(' +
            Math.floor(Math.random() * 255 + 1) +
            ', ' +
            Math.floor(Math.random() * 255 + 1) +
            ', ' +
            Math.floor(Math.random() * 255 + 1) +
            ')';

         var styles = {
            animation: animationContent,
            height: size,
            width: size,
            position: 'absolute',
            top: xpos,
            left: ypos,
            backgroundColor: colour,
            borderRadius: '50%',
            borderColor: bcolour,
            border: 10,
            zIndex: 100
         };

         tempDisplayObj.push(
            <div className='animateCircles' style={styles}></div>
         );
      }
      return tempDisplayObj;
   }
   var circleDisplayObj = createCircles();

   // display array of random circles is passed to HomePage to render
   return (
      <HomePage circles={circleDisplayObj} />
   )
};

export default PrepHomePage;
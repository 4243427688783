import React from 'react';
import { NavLink } from 'react-router-dom';

import Thumbnail from '../../Navigation/Thumbnail/Thumbnail';
import classes from './ProjSummary.module.css';

const ProjSummary = (props) => {

   var pathname = '/projects/' + (props.idx);
   var linkClasses = [];
   linkClasses.push(classes.linkItem);
   if (props.faint) {
      linkClasses.push(classes.faint);
   }

   var listClasses = [];

   if (props.active) {
      listClasses.push(classes.backgroundDiv);
   }

   return (
      <div className={classes.ProjSummary} >
         <Thumbnail
            proj={true}
            idx={props.idx}
            img={props.project}
            clickProps={props.clickProps}
            active={props.active}
            faint={props.faint}
         />
         <div className={listClasses.join(' ')}>
            <ul className={classes.List}>
               <div><NavLink
                  className={linkClasses.join(' ')}
                  activeClassName={classes.activeLinkItem}
                  to={{
                     pathname: pathname + '/overview',
                     state: { from: 'thumbs' }
                  }}
               >Overview</NavLink></div>

               <div><NavLink
                  className={linkClasses.join(' ')}
                  activeClassName={classes.activeLinkItem}
                  to={{
                     pathname: pathname + '/challenge',
                     state: { from: 'thumbs' }
                  }}
               >Challenge</NavLink></div>
               <div><NavLink
                  className={linkClasses.join(' ')}
                  activeClassName={classes.activeLinkItem}
                  to={{
                     pathname: pathname + '/process',
                     state: { from: 'thumbs' }
                  }}
               >Process</NavLink></div>
               <div><NavLink
                  className={linkClasses.join(' ')}
                  activeClassName={classes.activeLinkItem}
                  to={{
                     pathname: pathname + '/solution',
                     state: { from: 'thumbs' }
                  }}
               >Solution</NavLink></div>
            </ul>
         </div >
      </div >
   );
};

export default ProjSummary;
import React from 'react';
import { Link } from 'react-router-dom';

import classes from './About.module.css';

const getAboutData = () => {

   var aboutData = {
      statement: 'I design and create for people: balancing how things look (and feel), function, and fit into the broader context (clients, stakeholders, business, process, markets, etc).',
      skills: {
         coding: [
            {
               title: 'React',
               other: 'including react-router, redux, axios',
               examples: [
                  { title: 'scheduler', link: (<Link className={classes.inlineLink} to='/projects/7/overview'>Scheduler project</Link>) },
                  { title: 'online portfolio', link: (<Link className={classes.inlineLink} to='/projects/2/overview'>Online portfolio</Link>) },
                  { title: 'myburger', link: (<Link className={classes.inlineLink} to='/projects/6/overview'>myBurger project</Link>) }
               ]
            },
            {
               title: 'Javascript / HTML / CSS',
               other: '',
               examples: [
                  { title: 'scheduler', link: (<Link className={classes.inlineLink} to='/projects/7/overview'>Scheduler project</Link>) },
                  { title: 'online portfolio', link: (<Link className={classes.inlineLink} to='/projects/2/overview'>Online portfolio</Link>) },
                  { title: 'book exchanger', link: (<Link className={classes.inlineLink} to='/projects/1/overview'>Book exchanger project</Link>) },
                  { title: 'myburger', link: (<Link className={classes.inlineLink} to='/projects/6/overview'>myBurger project</Link>) }
               ]
            },
            {
               title: 'Angular',
               other: '',
               examples: [
                  { title: 'book exchanger', link: (<Link className={classes.inlineLink} to='/projects/1/overview'>Book exchanger project</Link>) }
               ]
            },
            {
               title: 'MySQL',
               other: '',
               examples: [
                  { title: 'book exchanger', link: (<Link className={classes.inlineLink} to='/projects/1/overview'>Book exchanger project</Link>) }
               ]
            },
            {
               title: 'node.js',
               other: 'including Express',
               examples: [
                  { title: 'book exchanger', link: (<Link className={classes.inlineLink} to='/projects/1/overview'>Book exchanger project</Link>) }
               ]
            },
         ],
         design: [

            {
               title: 'UI/UX design',
               other: '',
               examples: [
                  { title: 'scheduler', link: (<Link className={classes.inlineLink} to='/projects/7/overview'>Scheduler project</Link>) },
                  { title: 'online portfolio', link: (<Link className={classes.inlineLink} to='/projects/2/overview'>Online portfolio</Link>) },
                  { title: 'book exchanger', link: (<Link className={classes.inlineLink} to='/projects/1/overview'>Book exchanger project</Link>) },

               ]
            },

            {
               title: 'User research',
               other: '',
               examples: [
                  { title: 'inclinedesign sit-ski', link: (<Link className={classes.inlineLink} to='/projects/3/overview'>InclineDesign sit-ski</Link>) },
                  { title: 'Legal registry', link: (<Link className={classes.inlineLink} to='/projects/4/overview'>Legal registry</Link>) },
                  { title: 't-shirt designs', link: (<Link className={classes.inlineLink} to='/projects/5/overview'>T-shirt designs</Link>) },
               ]
            },
            {
               title: 'Visual design',
               other: '',
               examples: [
                  { title: 't-shirt designs', link: (<Link className={classes.inlineLink} to='/projects/5/overview'>T-shirt designs</Link>) },
                  { title: 'xmas cards', link: 'xmas cards' },
                  { title: 'online portfolio', link: (<Link className={classes.inlineLink} to='/projects/2/overview'>Online portfolio</Link>) },
                  { title: 'book exchanger', link: (<Link className={classes.inlineLink} to='/projects/1/overview'>Book exchanger project</Link>) },
                  { title: 'scheduler', link: (<Link className={classes.inlineLink} to='/projects/7/overview'>Scheduler project</Link>) }

               ]
            },
            {
               title: 'Sketching / Drawing',
               other: '',
               examples: [
                  { title: 'Developing icons', link: (<Link className={classes.inlineLink} to='/pictures/9'>Developing icons</Link>) },
                  { title: 'Pencil drawings', link: (<Link className={classes.inlineLink} to='/pictures/11'>Pencil drawing</Link>) },
                  { title: 't-shirt designs', link: (<Link className={classes.inlineLink} to='/projects/5/overview'>T-shirt designs</Link>) },
                  { title: 'online portfolio', link: (<Link className={classes.inlineLink} to='/projects/2/overview'>Online portfolio</Link>) },

               ]
            },
            {
               title: 'Adobe Illustrator, Photoshop, InDesign, LightRoom',
               other: '',
               examples: [
                  { title: 't-shirt designs', link: (<Link className={classes.inlineLink} to='/projects/5/overview'>T-shirt designs</Link>) },
                  { title: 'xmas cards', link: 'xmas cards' }
               ]
            }
         ],
         business: [
            {
               title: 'Project management',
               other: '',
               examples: [
                  { title: 'PMP', link: 'PMP' },
                  { title: 'Deloitte', link: 'Deloitte' },
                  { title: 'Legal registry', link: (<Link className={classes.inlineLink} to='/projects/4/overview'>Legal registry</Link>) },
                  { title: 'Tervita', link: 'Tervita' },
                  { title: 'inclinedesign sit-ski', link: (<Link className={classes.inlineLink} to='/projects/3/overview'>InclineDesign sit-ski</Link>) }
               ]
            },
            {
               title: 'Writing proposals and business cases',
               other: '',
               examples: [
                  { title: 'inclinedesign sit-ski', link: (<Link className={classes.inlineLink} to='/projects/3/overview'>InclineDesign sit-ski</Link>) },
                  { title: 'Deloitte', link: 'Deloitte' },
                  { title: 'Tervita', link: 'Tervita' }
               ]
            },
            {
               title: 'Stakeholder collaboration',
               other: '',
               examples: [
                  { title: 'Legal registry', link: (<Link className={classes.inlineLink} to='/projects/4/overview'>Legal registry</Link>) },
                  { title: 'Tervita', link: 'Tervita' },
                  { title: 'Deloitte', link: 'Deloitte' },
                  { title: 'ERM development', link: 'ERM development' }
               ]
            },
            {
               title: 'Developing and giving presentations',
               other: '',
               examples: [
                  { title: 'Legal registry', link: (<Link className={classes.inlineLink} to='/projects/4/overview'>Legal registry</Link>) },
                  { title: 'Tervita', link: 'Tervita' },
                  { title: 'Deloitte', link: 'Deloitte' },
                  { title: 'ERM development', link: 'ERM development' }
               ]
            },
            {
               title: 'Quantitative analysis (MS Excel)',
               other: '',
               examples: [
                  { title: 'inclinedesign sit-ski', link: (<Link className={classes.inlineLink} to='/projects/3/overview'>InclineDesign sit-ski</Link>) },
                  { title: 'Deloitte', link: 'Deloitte' },
                  { title: 'Tervita', link: 'Tervita' }
               ]
            },

         ]
      },
      contact: (
         <React.Fragment>
            <div>
               Please don't hesitate to reach out.
            </div>
            <div>
               <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.linkedin.com/in/jjmchew"
               >
                  www.linkedin.com/in/jjmchew
               </a>{' '}
               | jjmchew [at] gmail.com | +1.four03.975.716seven (mobile)
               <br />
               <br />
               <br />
            </div>
         </React.Fragment>
      )
   };

   return aboutData;
}

export default getAboutData;

// <img src={require('../../assets/JC.jpg')} className={classes.projectImg} alt="JC" />
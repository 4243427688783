import React, { useState, useCallback, useEffect } from 'react';

import getPgLayouts from './getPgLayouts';
import Draggable from '../UI/Draggable/draggable';
// import AssignWrapper from './AssignWrapper/AssignWrapper';
// import Shake from '../UI/Shake/Shake';
// import FlipCard from '../UI/Flip/FlipCard';
import RotateWord from '../HomePage/RotateWord/RotateWord';
import Skeleton from '../UI/Skeleton/Skeleton';
import Modal from '../UI/Modal/Modal';

import classes from './Homepage.module.css';

const adjectives1 = [
   'unique',
   'random',
   'funny',
   'engaging',
   'different',
   'special',
   'functional',
   'awesome',
   'useful',
   'unexpected',
   'distinctive',
   'game-changing',
   'incredible',
   'unprecedented',
   'excellent',
   'addictive',
   'seamless'
];

const HomePage = (props) => {

   // track which layout is being used on a particular page render
   const [layout, setLayout] = useState(0);
   const [clicks, setClicks] = useState(null);
   const [chosenElement, setChosenElement] = useState(null);
   const [curAdjective1, setcurAdjective1] = useState(adjectives1[4]);
   const [circlesOn, setCirclesOn] = useState(false);
   const [modalShow, setModalShow] = useState(false);
   const [skeletonDead, setSkeletonDead] = useState(false);

   const [pos, setPos] = useState({ x: 0, y: 0 });
   const measuredRef = useCallback(node => {
      if (node !== null) {
         setPos({ x: node.getBoundingClientRect().x + window.scrollX, y: node.getBoundingClientRect().y + window.scrollY });
         // console.log(node.getBoundingClientRect());
         // console.log(window.scrollX, window.scrollY);
         console.log('in CALLBACK', node.getBoundingClientRect().x, node.getBoundingClientRect().y);
      }
   }, []);

   var pgLayouts = getPgLayouts();
   var len = {
      head: pgLayouts[layout].head.length,
      word: pgLayouts[layout].word.length,
      bullet: pgLayouts[layout].bullet.length,
      contact: pgLayouts[layout].contact.length
   };
   // console.log(len.head, len.word, len.bullet, len.contact);
   var numElements = len.head + len.word + len.bullet + len.contact;

   var displayObj = { head: [], word: [], bullet1: [], bullet: [], contact1: [], contact: [] }

   const changeLayout = () => {
      if (clicks === null) { setClicks(0) }

      // toggle layouts for testing
      if (layout === 1) {
         setLayout(0);
      } else {
         setLayout(1);
      }
      var temp = Math.floor(Math.random() * numElements);
      setChosenElement(temp);
      console.log('TOGGLE CLICKED.  chosenElement: ', temp);
   }

   const changeAdj1 = () => {
      let rand = Math.floor(Math.random() * adjectives1.length);
      setcurAdjective1(adjectives1[rand]);
      // console.log('[changeAdj1]');
   }

   const setupBasicDisplayObj = () => {
      for (var i = 0; i < len.head; i++) {
         displayObj.head.push(pgLayouts[layout].head[i]);
      }
      for (i = 0; i < len.word; i++) {
         displayObj.word.push(<RotateWord className={classes.Word} word={curAdjective1} />);
      }
      displayObj.bullet1.push(pgLayouts[layout].bullet1);
      for (i = 0; i < len.bullet; i++) {
         displayObj.bullet.push(pgLayouts[layout].bullet[i]);
      }
      displayObj.contact1.push(pgLayouts[layout].contact1);
      for (i = 0; i < len.contact; i++) {
         // console.log('no equality');
         displayObj.contact.push(pgLayouts[layout].contact[i]);

      }
      // console.log('[setupBasicDisplayObj]');
   }
   const setupChosenElement = () => {
      var idx = chosenElement;

      if (idx < len.head) {
         displayObj.head[idx] = (<div className={classes.setLayer} ref={measuredRef}><Draggable size="lg" clickProps={handleClick}>{pgLayouts[layout].head[idx]}</Draggable></div>);

         // console.log('[setupChosenElement] case1', idx);
      } else if (idx < (len.head + len.word)) {
         idx = idx - len.head;
         displayObj.word[idx] = (<div className={classes.setLayer} ref={measuredRef}><Draggable size="lg" clickProps={handleClick}><RotateWord className={classes.Word} word={curAdjective1} /></Draggable></div>);

         // console.log('[setupChosenElement] case2', idx);
      } else if (idx < (len.head + len.word + len.bullet)) {
         idx = idx - (len.head + len.word);
         displayObj.bullet[idx] = (<div className={classes.setLayer} ref={measuredRef}><Draggable size="sm" clickProps={handleClick}>{pgLayouts[layout].bullet[idx]}</Draggable></div>);

         // console.log('[setupChosenElement] case3', idx);
      } else if (idx < (len.head + len.word + len.bullet + len.contact)) {
         idx = idx - (len.head + len.word + len.bullet);

         displayObj.contact[idx] = (<div className={classes.setLayer} ref={measuredRef}><Draggable size="sm" clickProps={handleClick}>{pgLayouts[layout].contact[idx]}</Draggable></div>);
         // console.log('used contact frag object');

         // console.log('[setupChosenElement] case4', idx);
      }
      // console.log(displayObj);
   }


   useEffect(() => {
      var adjTimer1 = setInterval(changeAdj1, 4000);
      return () => {
         clearInterval(adjTimer1);
      }
   }, []);

   const handleClick = () => {
      var newClicks = clicks + 1;
      setClicks(newClicks);
      console.log('clicks: ', newClicks);
      changeLayout();
      setCirclesOn(!circlesOn);
      setTimeout(() => {
         setCirclesOn(false);
      }, 3000);
   }

   setupBasicDisplayObj();
   setupChosenElement();

   var buttonMsg
   if (clicks === null) { buttonMsg = `Let's Play!` }
   else { buttonMsg = 'Reset'; }

   var circlesDisplayObj = props.circles;

   const handleModalClick = () => {
      console.log('clicked');
      setModalShow(!modalShow);
   }

   const handleSkeletonClick = () => {
      setSkeletonDead(true);
      console.log('skeleton dead');
   }

   return (
      <React.Fragment>

         <Modal clicks={clicks} clickProps={handleModalClick} show={modalShow} skeletonDead={skeletonDead} />
         <Skeleton clickProps={handleSkeletonClick} show="click" />

         <div className={classes.playButton} onClick={changeLayout}>{buttonMsg}</div>
         <div className={classes.mainPage}>

            <div className={classes.contHead}>
               {displayObj.head}
            </div>
            <div className={classes.contWord}>
               {displayObj.word}
            </div>
            <br />
            <br />
            <div className={classes.flexRow}>
               <div className={classes.flexRow}>
                  <div>
                     {displayObj.bullet1}
                  </div>
                  <div className={classes.contBullet}>
                     {displayObj.bullet}
                  </div>
               </div>
               <div className={classes.flexRow}>
                  <div>
                     {displayObj.contact1}
                  </div>
                  <div className={classes.contContact}>
                     {displayObj.contact}
                  </div>
               </div>
            </div>
            <br />

         </div>
         {circlesOn ? circlesDisplayObj : null}
      </React.Fragment>
   )
};

export default HomePage;

import React from 'react';
import { Link } from 'react-router-dom';
import classes from './SubNav.module.css';

const SubNav = (props) => {
   var prev = null;
   var next = null;
   if (props.prev !== null) {
      prev = (
         <Link to={props.prev}>
            <img
               src={require('../../../assets/ico-back.png')}
               className={classes.buttonIcon}
               alt="Previous"
            />
         </Link>
      );
   } else {
      prev = <div className={classes.buttonIcon} />
   }
   if (props.next !== null) {
      next = (
         <Link to={props.next}>
            <img
               src={require('../../../assets/ico-fwd.png')}
               className={classes.buttonIcon}
               alt="Next"
            />
         </Link>
      );
   }
   return (
      <div className={classes.SubNav}>
         {prev}
         {' '}
         <div className={props.proj ? classes.projTitle : classes.picTitle}>
            {props.title}{' '}
         </div>
         {next}
      </div>
   );
};

export default SubNav;